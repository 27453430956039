import React, { useState } from "react"
import { Box, Heading, Paragraph } from "grommet"

export const GameBox = (props) =>
{
	const notLarge = props.notLarge,
				size     = props.size,
				isSmall  = size === "small",
				gameSlug = props.gameSlug,
				gameData = props.gameData

	const is404 = window.pageType && window.pageType === "404"

	const useMobileThumb = !!gameData[5] && (notLarge || is404)
	const imgSize = useMobileThumb || is404 ? 100 : 200

	const leftPad = "15px"

	return (<a className="front-page-link" href={"/" + gameSlug}>
			<Box
				flex
				direction="row"
				className="front-gamebox"
				justify="between"
				gap="none"
				align="start"
				basis={isSmall ? "full" : "1/2"}
				height={imgSize + "px"}
				elevation="large"
				background="white"
			>
				<img src={window.imageDomain + "/images/games/" + (useMobileThumb ? "mobile/" + gameData[5] : "small/" + gameData[3])}
						 alt={useMobileThumb ? gameData[7] : gameData[6]} width={imgSize} height={imgSize}/>
				<Box
					flex
					direction="column"
					// justify="between"
					align="stretch"
				>
					<Box
						overflow="hidden"
						basis={notLarge ? "75px" : "175px"}
						pad={{ right: "8px", top: "8px" }}
					>
						<Heading
							level={2}
							size={notLarge ? "1rem" : "1.5rem"}
							alignSelf="stretch"
							margin={{ left: leftPad, bottom: "0" }}
						>
							{is404 ? <small>See your pings for<br/><span style={{lineHeight: "1", display: "inline-block"}}>{gameData[0]}</span></small> : <span style={{lineHeight: "1", display: "inline-block"}}>{gameData[0]}</span>}

						</Heading>

						{is404
						 ? null
						 : <Paragraph
							size={"small"}
							fill
							margin={{ top: isSmall ? "0" : "8px", left: leftPad }}
							className="front-game-blurb"
							alignSelf="center"
						>
							{gameData[1]}
						</Paragraph>}

					</Box>
					{is404
					 ? null
					 : <Box
						flex
						direction="row"
						basis="25px"
						align="center"
						className="front-ping-link"
						pad={{ left: leftPad }}>
						see {gameData[0]}
					</Box>}
				</Box>
			</Box></a>
	)
}