import React from "react"

import {Box} from "grommet"

const GspLogoText = (size = "100%") =>
{
	return <Box
		className="gsp-logotext"
		direction="row"
		align="center"
		flex
		justify="between"
	>
		<div>Game Server</div> <span>PING</span></Box>
}

export default GspLogoText