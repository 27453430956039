import React from "react"

import { Box, Grommet, ResponsiveContext } from "grommet"

import HeaderBar from "./components/HeaderBar"
import Games from "./components/front/Games"
import SideBar from "./components/Sidebar"

import GSPtheme from "./theme"

import {loadGlobal, saveGlobal} from "./lib/stateMates"

export default class App extends React.Component
{
	constructor(props) {
		super(props);
		this.state = loadGlobal()
	}

	flipMode = () =>
	{
		this.setState((state) => ({
			themeMode: state.themeMode === "light" ? "dark" : "light"
		}))
	}

	componentDidUpdate (prevProps, prevState, snapshot)
	{
		if (prevState.themeMode !== this.state.themeMode) // Persist the theme mode setting
		{
			saveGlobal(this.state)
		}
	}

	render ()
	{
		return (
			<Grommet theme={GSPtheme} full themeMode={this.state.themeMode}>

				<ResponsiveContext.Consumer>
					{size => (
						<Box direction="row" flex align="start"
								 background={{ light: "#b5c6e1", dark: "#383d45" }}>
							<SideBar
								side="left"
								size={size}
								client="ca-pub-9362258405655236"
								slot="8676121358"
							/>
							<Box
								flex
								direction="column"
								align="center"
								justify="start"
								basis="large"
								style={{
									maxWidth: (size === "small" || size === "medium")
														? GSPtheme.global.breakpoints[size]
														: "930px",
									width:    "100%"
								}}
							>
								<HeaderBar flipMode={this.flipMode}/>
								<br/>
								<Games/>
							</Box>
							<SideBar
								side="right"
								size={size}
								client="ca-pub-9362258405655236"
								slot="2302284698"
							/>
						</Box>
					)}
				</ResponsiveContext.Consumer>

			</Grommet>
		)
	}
}
