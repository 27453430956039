import ls from "local-storage"

import defaultState from "../defaultState"

const defaultGlobal = { themeMode: "light" }

export const loadGlobal = () =>
{
	const savedGlobal = ls.get("GSP-global")

	return !!savedGlobal
				 ? JSON.parse(savedGlobal)
				 : defaultGlobal
}

export const saveGlobal = (data) =>
{
	let oldGlobal = loadGlobal()

	ls.set("GSP-global", JSON.stringify({ ...oldGlobal, ...data }))
}

export const loadStateFromLocal = () =>
{
	const oldState = ls.get("GSP-" + window.gameSlug)

	return !!oldState
				 ? JSON.parse(oldState)
				 : {}
}

export const saveStateToLocal = (data) =>
{
	data.ui.progressMessage = null
	ls.set("GSP-" + window.gameSlug, JSON.stringify(data))
}

export const initState = () =>
{
	let returnState = {}

	if (!!window.snapShot)
	{
		const snapState = window.snapShotServerState

		returnState = { ...defaultState }

		returnState.pings = snapState.pings
		returnState.servers = snapState.servers

		returnState.ui.layout = "list"
		returnState.ui.progressMessage = null
	}
	else
	{
		const oldState = loadStateFromLocal()
		returnState = { ...defaultState, ...oldState, "progressMessage": "test" }

		// Populate pings list with servers
		if (!!!returnState.pings.servers)
		{
			returnState.pings.servers = {}

			if (!!!returnState.servers)
			{
				returnState.servers = window.defaultServers
			}

			Object.keys(returnState.servers).forEach(
				server =>
				{
					returnState.pings.servers[server] = []
				})
		}
	}

	return returnState
}