import { Box } from "grommet"
import React from "react"

export default class SideBar extends React.Component
{
	constructor (props)
	{
		super(props)
		this.myRef = React.createRef()

		this.tryCount = 0
	}

	componentDidMount ()
	{
		if (!!this.props.client)
		{
			this.pushMe()
		}
	}

	pushMe = () =>
	{
		// console.log("Try "+this.tryCount)

		let clname = "advert-sidebar-" + this.props.side,
				boxRef = this.myRef
		// console.log(boxRef)
		// console.log(clname,
		// document.querySelector(".advert-sidebar-"+this.props.side))

		if (!!this.myRef.current)
		{
			// console.log(this.myRef.current.clientWidth + " wide")
			window.adsbygoogle = window.adsbygoogle || []
			window.adsbygoogle.push({})
		}
		else
		{
			this.tryCount++
			if (this.tryCount < 8)
			{
				setTimeout(this.pushMe, 1000)
			}
		}
	}

	render ()
	{
		let props = this.props

		return (
			this.props.size !== "small" && this.props.size !== "medium"
			? (
				<Box
					flex
					basis="auto"
					align="center"
					justify="center"
					key={"sidebar-" + props.size + "-" + props.side}
					className={"advert-sidebar-" + props.side}
					ref={this.myRef}
					style={{ paddingTop: "90px", textAlign: "center" }}
				>
					<ins className="adsbygoogle"
							 style={{ display: "inline-block", width: "160px", height: "600px" }}
							 data-ad-client={props.client}
							 data-ad-slot={props.slot}
							 data-ad-format="auto"
							 data-full-width-responsive="true"
					/>
				</Box>
			)
			: null
		)
	}
}