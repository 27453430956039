import React, { Suspense } from "react"
import {
	Anchor,
	Box,
	Button,
	Header,
	Menu,
	ResponsiveContext,
	ThemeContext
} from "grommet"

import { Info } from "grommet-icons"

import pingsLogo from "../resources/images/gameserverping-logo-square-border.png"
import GspLogoText from "./gsptext"

const SnapshotHeader = React.lazy(() => import('./SnapshotHeader'));


const HeaderBar = (props) =>
{
	const size = React.useContext(ResponsiveContext)
	const theme = React.useContext(ThemeContext)

	const smallButtonStyle = { borderRadius: 0, marginBottom: "4px", padding: "0 15px" }
	return (
		<>
			<Header pad="none"
							background="bgStandard"
							style={{
								position: "fixed",
								width:    "inherit",
								maxWidth: "inherit",
								zIndex:   9999
							}}
							gap="none"
			>
				<Anchor
					href="/"
					margin="none"
					focusIndicator={window.isTabbing}
				>
					<Box
						flex
						direction="row"
						justify="between"
						align="center"
						gap="medium"
						pad="none"
					>
						<img src={pingsLogo} alt="game server ping"/>
						{size !== "small"
						 ? <GspLogoText size={size}/>
						 : null}
					</Box>
				</Anchor>

				<Button
					icon={<Info color={theme.dark ? "white" : "black"}
											style={{ height: "1rem" }}/>}
					label={ size !== "small" ? "Go " + (theme.dark ? "light" : "dark"): null}
					onClick={() => props.flipMode()}
					style={{ fontSize: ".8rem", fontWeight: "normal" }}
					plain={true}
					gap="xxsmall"
					reverse={true}
					focusIndicator={window.isTabbing}
				/>
				<Menu
					label={ size !== "small" ? "game pings" : "pings"}
					margin={ size === "small" ? {horizontal: "none"} : "small"}
					size={size === "small" ? "small" : "medium"}
					weight="bold"
					items={Object.keys(window.gameList)
						.map(gameSlug =>
								 {
									 return { label: window.gameList[gameSlug][0], href: "/" + gameSlug }
								 })}
				/>
				<Menu
					label={ size !== "small" ? "game status" : "status"}
					margin={ size === "small" ? {horizontal: "none"} : "small"}
					size={size === "small" ? "small" : "medium"}
					weight="bold"
					items={Object.keys(window.gameList)
						.filter(gameSlug =>
										{
											return window.gameList[gameSlug][8]
										})
						.map(gameSlug =>
								 {
									 return { label: window.gameList[gameSlug][0], href: "/status/" + gameSlug }
								 })}
					dropProps={
						{
							elevation: "small",
							align: { left: "left", top: "bottom"}
						}
					}
					// margin={{top: "20px"}}
				/>
				<Anchor
					label="articles"
					size={size === "small" ? "small" : "medium"}
					href="/articles"
					style={{fontWeight: "normal"}}
					margin={ size === "small" ? { right: "small" } : {}}
					color={theme.dark ? "white" : "black"}
				/>
			</Header>
			{size === "small" && !!props.ui
			 ? <Box
				 direction="row"
				 background="bgStandard"
				 alignContent="center"
				 justify="around"
				 flex
				 style={{
					 position:  "fixed",
					 width:     "inherit",
					 maxWidth:  "inherit",
					 zIndex:    9999,
					 marginTop: "49px",
					 height:    "32px"
				 }}>
				 <Button
					 label="list"
					 size="small"
					 plain={false}
					 focusIndicator={window.isTabbing}
					 active={props.ui.layout === "list"}
					 hoverIndicator={true}
					 style={smallButtonStyle}
					 onClick={() => { props.layoutToggle("list") }}
				 />
				 <Button
					 label="meter"
					 size="small"
					 plain={false}
					 focusIndicator={window.isTabbing}
					 active={props.ui.layout === "meter"}
					 hoverIndicator={true}
					 style={smallButtonStyle}
					 onClick={() => { props.layoutToggle("meter") }}
				 />
			 </Box>
			 : null}
			{window.snapShot ? <Suspense fallback={<div>Loading...</div>}><SnapshotHeader size={size} theme={theme}/></Suspense> : null}
		</>
	)
}

export default HeaderBar