const defaultState = {
	ui: {
		allServersView: "list",
		featuredServer: null,
		activeProfile:  "Default",
		initialised:    false,
		newServerModal: false,
		snapshotModal: false,
		newServerData:  {},
		newServersList: [],
		toasty:         {},
		layout:         "split",
		isSmall:        false,
		doPings:        false,
		graphPeriod:    "90s", // 90s|10m|1h|6h
		progressMessage: null,
	},

	servers: window.defaultServers,

	pings: {
		profiles: ["Default"],
		current:  [],

		servers: null
	}
}

export default defaultState
