import { deepMerge } from "grommet/utils"
import { grommet } from "grommet"

const theme = {
	button:      {
		border: {
			radius: "9px",
			width:  "1px"
		}
	},
	heading:     {
		weight: "normal",
		level:  {
			"1": {
				"small":  {
					"size": "28px"
				},
				"medium": {
					"size": "36px"
				},
				"large":  {
					"size": "42px"
				}
			},
			"2": {
				"small":  {
					"size": "20px"
				},
				"medium": {
					"size": "24px"
				},
				"large":  {
					"size": "26px"
				}
			}
		}
	},
	global:      {
		cardPadding: "1.5rem",
		colors:      {
			brand:      "#228BE6",
			"light-1":  "rgba(248,248,248,0.47)",
			bgStandard: {
				light: !!window.gameColor ? window.gameColor : "#b5c6e1",
				dark:  "#383d45"
			},
			background: {
				light: !!window.gameColor ? window.gameColor : "#b5c6e1",
				dark:  "#383d45"
			},
		},
		font:        {
			family:  "'Open Sans', 'Helvetica Nue', sans-serif",
			face:
							 `@font-face {
				font-family: "Open Sans";
				src: url("https://fonts.googleapis.com/css?family=Black+Ops+One|Montserrat|Nunito+Sans:400,700|Open+Sans:300,400,800&display=swap")
			}`,
			display: "swap",
			size:    ".8rem",
			height:  "20px",
		},
		anchor:      {
			hover:      {
				textDecoration: "none",
				extend:         "text-decoration: none !important;",
				color:          "black"
			},
			fontWeight: "400 !important"
		},
		breakpoints: {
			"medium": { "value": 1400 },
			"small":  { "value": 800 },
		}
	},
	collapsible: {
		minSpeed: 2000
	}
}

export default deepMerge(grommet, theme)