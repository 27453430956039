import React from "react"
import {
	Anchor,
	Box,
	Grid,
	Heading,
	Paragraph,
	ResponsiveContext,
	Text
} from "grommet"

import { GameBox } from "./GameBox"

export default () =>
{
	const size = React.useContext(ResponsiveContext)

	const notLarge = size === "small" || size === "medium"
	const isSmall = size === "small"

	const is404 = window.pageType && window.pageType === "404"

	return (
		<Box
			// background="white"
			// pad="small"
			style={{ paddingTop: "60px", maxWidth: "930px" }}
		>
			{is404
			 ? <Box
			 	justify="center"
				pad={{bottom: "40px"}}
			 >
				 <Heading
					 level={1}
					 textAlign="center"
				 >
					 404 Error - Not Found
				 </Heading>
				 <Text textAlign="center">
					 The page you were looking for could not be found.
				 </Text>
				 <Text textAlign="center">
					 You can check your own pings to servers for any of the games below!
				 </Text>
			 </Box>
			 : null}
			<Grid
				rows={notLarge ? "100px" : "200px"}
				columns={isSmall ? "100%" : ["48%", "48%"]}
				gap={notLarge ? "medium" : "4%"}
			>
				{Object.keys(window.gameList).map(gameSlug =>
																					{
																						return <GameBox
																							notLarge={notLarge}
																							size={size}
																							gameSlug={gameSlug}
																							gameData={window.gameList[gameSlug]}
																							key={gameSlug}
																						/>
																					})}
				<br/>
				<Box align="center" style={!isSmall ? {
					gridColumnStart: "1", gridColumnEnd: "3"
				} : {}}>
					<Anchor href="/privacy-policy">Privacy Policy</Anchor>
				</Box>

			</Grid>
		</Box>
	)
}